import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_cpass_account = _resolveComponent("cpass-account")!
  const _component_whatsapp = _resolveComponent("whatsapp")!
  const _component_getting_started_faq_cpaas = _resolveComponent("getting-started-faq-cpaas")!
  const _component_getting_started_faq_whatsapp = _resolveComponent("getting-started-faq-whatsapp")!
  const _component_faq_block = _resolveComponent("faq-block")!
  const _component_getting_started = _resolveComponent("getting-started")!

  return (_openBlock(), _createBlock(_component_getting_started, null, {
    faq: _withCtx(() => [
      _createVNode(_component_faq_block, {
        title: "Getting started FAQ",
        text: "Haven’t found what you were looking for?",
        tabs: _ctx.tabs
      }, {
        "panel-cpaas": _withCtx(() => [
          _createVNode(_component_getting_started_faq_cpaas)
        ]),
        "panel-whatsapp": _withCtx(() => [
          _createVNode(_component_getting_started_faq_whatsapp)
        ]),
        _: 1
      }, 8, ["tabs"])
    ]),
    default: _withCtx(() => [
      _createVNode(_component_cpass_account, { class: "mb-3" }),
      _createVNode(_component_whatsapp)
    ]),
    _: 1
  }))
}