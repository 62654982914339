
import { defineComponent, ref } from 'vue'
import GettingStarted from '@/components/pages/gettingStarted/GettingStarted.vue'
import CpassAccount from '@/components/pages/gettingStarted/gettingStartedBlocks/CpassAccount.vue'
import Whatsapp from '@/components/pages/gettingStarted/gettingStartedBlocks/Whatsapp.vue'
import FaqBlock from '@/components/shared/templates/FaqBlock.vue'
import GettingStartedFaqCpaas from '@/components/pages/gettingStarted/GettingStartedFaqCpaas.vue'
import GettingStartedFaqWhatsapp from '@/components/pages/gettingStarted/GettingStartedFaqWhatsapp.vue'

export default defineComponent({
  components: {
    GettingStarted,
    CpassAccount,
    Whatsapp,
    FaqBlock,
    GettingStartedFaqCpaas,
    GettingStartedFaqWhatsapp,
  },
  setup() {
    const tabs = ref([
      {
        name: 'cpaas',
        label: 'CPaaS integrations',
      },
      {
        name: 'whatsapp',
        label: 'WhatsApp',
      },
    ])

    return {
      tabs,
    }
  },
})
